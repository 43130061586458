import gql from 'graphql-tag';

const QUERY_ACCOUNT = gql`
  query($company: String!, $companyType: String!) {
    getAccounts(company: $company, companyType: $companyType) {
      _id
      email
      name
      nickname
      avatar
      phone
      gender
      birthday
      address
      accountType
      taxCode
      bio
      role {
        _id
      }
      companies {
        _id
      }
      unions {
        _id
      }
      nation {
        _id
        code
        name
      }
      city {
        _id
        code
        name
      }
      state {
        _id
        code
        name
      }
      status
      createdDate
    }
  }
`;

const QUERY_ACCOUNT_INFO = gql`
  query {
    getAccountInfo {
      _id
      avatar
      email
      name
      nickname
      avatar
      phone
      gender
      birthday
      address
      accountType
      gopy
      taxCode
      bio
      organizationCode
      zipcode
      gender
      website
      representative
      representativeEmail
      representativePhone
      representativePosition
      role {
        _id
      }
      companies {
        _id
      }
      unions {
        _id
      }
      companyType {
        _id
        value
      }
      companySize {
        _id
        value
      }
      companyIndustry {
        _id
        value
      }
      establishedDate
      markets {
        _id
      }
      mainIndustries {
        _id
      }
      mainProducts {
        _id
      }
      mainCustomers {
        _id
      }
      nation {
        _id
        name
      }
      city {
        _id
        name
      }
      state {
        _id
        name
      }
      maritalStatus
      income
      educationBackground
      university
      universityMajor
      universityPeriod
      highSchool
      highSchoolPeriod
      secondarySchool
      secondarySchoolPeriod
      status
      createdDate
      package{
        _id
      }
      company{
        _id
      }
      influencer{
        _id
      }
    }
  }
`;

const QUERY_ACCOUNT_BY_ID = gql`
query($id: String!) {
  findAccountById(id: $id) {
    _id
    email
    name
    nickname
    avatar
    phone
    gender
    birthday
    address
    accountType
    taxCode
    bio
    role {
      _id
    }
    companies {
      _id
    }
    unions {
      _id
    }
    company {
      _id
      information {
        name
      }
      rating {
        star
      }
    }
    union {
      _id
      information {
        name
      }
      rating {
        star
      }
    }
    influencer {
      _id
      information {
        name
      }
      rating {
        star
      }
    }
    companyIndustry {
      value
    }
    companyType {
      value
    }
    companySize {
      value
    }
    nation {
      _id
      code
      name
    }
    city {
      _id
      code
      name
    }
    state {
      _id
      code
      name
    }
    status
    createdDate
      package {
      _id
    }
  }
}
`;

const QUERY_ACCOUNT_BY_IDS = gql`
query($ids: [String!]) {
  findAccountByIds(ids: $ids) {
    _id
    email
    name
    nickname
    avatar
    accountType
    createdDate
  }
}
`;

const QUERY_ORGANIZATION_WITH_FILTER = gql`
  query($input: AccountFilter!) {
    getAccountsWithFilter(input: $input) {
      total
      page
      pageSize
      data {
        _id
        avatar
        email
        name
        nickname
        avatar
        phone
        gender
        birthday
        address
        accountType
        taxCode
        bio
        organizationCode
        zipcode
        gender
        website
        representative
        representativeEmail
        representativePhone
        representativePosition
        role {
          _id
        }
        companies {
          _id
        }
        unions {
          _id
        }
        companyType {
          _id
          value
        }
        companySize {
          _id
          value
        }
        companyIndustry {
          _id
          value
        }
        establishedDate
        markets {
          _id
        }
        mainIndustries {
          _id
        }
        mainProducts {
          _id
        }
        mainCustomers {
          _id
        }
        nation {
          _id
          name
        }
        city {
          _id
          name
        }
        state {
          _id
          name
        }
        status
        createdDate
        package {
          _id
        }
        company {
          _id
          averageRating
        }
        influencer {
          _id
          averageRating
        }
      }
    }
  }
`;

const QUERY_ORGANIZATION_NETWORK = gql`
  query($input: AccountFilter!) {
    findAccountNetwork(input: $input) {
      total
      data {
        _id
        avatar
        email
        name
        nickname
        avatar
        phone
        gender
        birthday
        address
        accountType
        friendStatus
        taxCode
        bio
        organizationCode
        zipcode
        gender
        website
        representative
        representativeEmail
        representativePhone
        representativePosition
        lat
        lng
        companyType {
          _id
          value
        }
        companySize {
          _id
          value
        }
        companyIndustry {
          _id
          value
        }
        establishedDate
        createdDate
        markets {
          _id
          value {
            code
            text
          }
        }
        mainIndustries {
          _id
          value {
            code
            text
          }
        }
        mainProducts {
          _id
          value {
            code
            text
          }
        }
        mainCustomers {
          _id
          value {
            code
            text
          }
        }
        nation {
          _id
          name
        }
        city {
          _id
          name
        }
        state {
          _id
          name
        }
        company {
          _id
          averageRating
        }
        influencer {
          _id
          averageRating
        }
      }
    }
  }
`;

const QUERY_EMPLOYEE_NETWORK = gql`
  query($input: AccountFilter!) {
    findEmployeeNetwork(input: $input) {
      total
      data {
        _id
        avatar
        email
        name
        nickname
        avatar
        phone
        gender
        birthday
        address
        accountType
        friendStatus
        taxCode
        bio
        organizationCode
        zipcode
        gender
        website
        representative
        representativeEmail
        representativePhone
        representativePosition
        lat
        lng
        createdDate
        nation {
          _id
          name
        }
        city {
          _id
          name
        }
        state {
          _id
          name
        }
      }
    }
  }
`;

const QUERY_ACCOUNT_WITH_FILTER = gql`
  query($input: AccountFilter!) {
    getAccountsWithFilter(input: $input) {
      total
      page
      pageSize
      data {
        _id
        avatar
        email
        name
        nickname
        avatar
        phone
        gender
        birthday
        address
        accountType
        taxCode
        bio
        zipcode
        gender
        role {
          _id
        }
        companies {
          _id
        }
        unions {
          _id
        }
        nation {
          _id
          name
        }
        city {
          _id
          name
        }
        state {
          _id
          name
        }
        maritalStatus
        income
        educationBackground
        university
        universityMajor
        universityPeriod
        highSchool
        highSchoolPeriod
        secondarySchool
        secondarySchoolPeriod
        status
        createdDate
        union{
          information{
            name
          }
        }
        company{
          information{
            name
          }
        }
        influencer{
          information{
            name
          }
        }
      }
    }
  }
`;

const QUERY_ACCOUNT_MEDICAL = gql`
  query($company: String!, $companyType: String!) {
    getAccounts(company: $company, companyType: $companyType) {
      _id
      name
      nickname
      avatar
      gender
      birthday
      vaccine {
        _id
        # content
        # documents {
        #   id
        #   name
        #   ext
        #   type
        #   size
        # }
        vaccine
        vaccineType
        date
        status
      }
      medical {
        _id
        # content
        # documents {
        #   id
        #   name
        #   ext
        #   type
        #   size
        # }
        type
        date
        expiredDate
        isPositive
        status
      }
    }
  }
`;

const QUERY_NOFIFY_ACCOUNT = gql`
  query($company: String!, $companyType: String!, $isAdmin: Boolean) {
    getNotifyAccounts(company: $company, companyType: $companyType, isAdmin: $isAdmin) {
      _id
      email
      name
      nickname
      avatar
      phone
      gender
      birthday
      address
      accountType
      taxCode
      bio
      companies {
        _id
      }
      unions {
        _id
      }
      nation {
        _id
        code
        name
      }
      city {
        _id
        code
        name
      }
      state {
        _id
        code
        name
      }
      status
      createdDate
    }
  }
`;

const QUERY_COUNT_ACCOUNT_SUMMARY = gql`
  query($companyType: String!) {
    countAccountSummary(companyType: $companyType) {
      count
      countCompany
      countUnion
      countInfluencer
    }
  }
`;

const QUERY_COMPANY_INFO = gql`
  query($company: String!, $companyType: String!) {
    getCompanyAccountInfo(company: $company, companyType: $companyType) {
      _id
      email
      name
      nickname
      avatar
      phone
      address
      taxCode
      bio
      companyType {
        value
      }
      companySize {
        value
      }
      companyIndustry {
        value
      }
      nation {
        name
      }
      city {
        name
      }
      state {
        name
      }
      company {
        rating {
          star
        }
      }
      union {
        rating {
          star
        }
      }
      organizationCode
      zipcode
      website
      representative
      representativeEmail
      representativePhone
      representativePosition
      status
      createdDate
    }
  }
`;

const QUERY_ACCOUNT_FOR_POST = gql`
  query($companyType: String!) {
    findAccountsForPost(companyType: $companyType) {
      _id
      nickname
      avatar
    }
  }
`;

const QUERY_COMPANY_ACCOUNT_BY_ROLE = gql`
  query($companyType: String!) {
    getCompanyAccountsByRole(companyType: $companyType) {
      _id
      avatar
      name
	    email
      accountType
      company {
        _id
        createdDate
      }
      union {
        _id
        createdDate
      }
      companyType {
        _id
        value
      }
      companySize {
        _id
        value
      }
      companyIndustry {
        _id
        value
      }
      nation {
        _id
        name
      }
      city {
        _id
        name
      }
      state {
        _id
        name
      }
      package{
        _id
      }
    }
  }
`;

const QUERY_REQUEST_ACCOUNT = gql`
  query ($accountType: String!) {
    findRequestAccounts (accountType: $accountType) {
      _id
      email
      name
      nickname
      avatar
      phone
      gender
      birthday
      address
      taxCode
      bio
      representative
      representativePosition
      companyType {
        _id
        value
      }
      companySize {
        _id
        value
      }
      companyIndustry {
        _id
        code
        value
      }
      nation {
        _id
        code
        name
      }
      city {
        _id
        code
        name
      }
      state {
        _id
        code
        name
      }
      createdDate
    }
  }
`;

const QUERY_REPORTED_ACCOUNT = gql`
  query {
    getReportedAccounts {
      _id
      account {
        _id
        name
        nickname
        avatar
        email
        phone
        address
        gender
        birthday
      }
      description
      status
      createdDate
      createdBy {
        _id
        nickname
        avatar
        address
      }
    }
  }
`;

const QUERY_PENDING_INDIVIDUAL_REQUEST = gql`
  query($companyType: String!) {
    getPendingIndividualRelationships(companyType: $companyType) {
      _id
      user {
        _id
        avatar
        email
        phone
        name
        nickname
        birthday
        gender
        address
      }
      company {
        _id
        type
        information {
          name
        }
      }
      createdDate
    }
  }
`;

const QUERY_PENDING_FRIEND_BY_USER = gql`
  query {
    findPendingFriendsByUser {
      _id
      createdBy {
        _id
        name
        nickname
        avatar
        email
        phone
        gender
        birthday
      }
      status
      createdDate
    }
  }
`;

const QUERY_PENDING_FRIEND_BY_USER_WITH_FILTER = gql`
  query ($page: Int, $pageSize: Int) {
    findPendingFriendsByUserWithFilter (page: $page, pageSize: $pageSize) {
      total
      data {
        _id
        createdBy {
          _id
          name
          nickname
          avatar
          email
          phone
          gender
          birthday
        }
        status
        createdDate
      }
    }
  }
`;

const QUERY_COMPANY_BY_GOVERMENT = gql`
  query($input: AccountSummaryInput!) {
    getCompanyAccountsByGoverment(input: $input) {
      _id
      email
      name
      avatar
      accountType
      company {
        _id
        rating {
          star
        }
      }
      union {
        _id
        rating {
          star
        }
      }
      influencer {
        _id
        rating {
          star
        }
      }
      companyIndustry {
        _id
        value
      }
      companyType {
        value
      }
      companySize {
        value
      }
      nation {
        _id
        name
      }
      city {
        _id
        name
      }
      state {
        _id
        name
      }
      status
      bio
      phone
      createdDate
    }
  }
`;

const QUERY_ACCOUNT_BY_COMPANY_ID = gql`
  query($companyIds: [String!]) {
    countAccountByCompany(companyIds: $companyIds) {
      _id
      count
    }
  }
`;

const QUERY_FIND_INDIVIDUAL_ACCOUNT = gql`
  query($text: String!) {
    findIndividualAccounts(text: $text) {
      _id
      avatar
      name
      nickname
      email
      phone
      company {
        _id
        information {
          avatar
          name
        }
      }
      union {
        _id
        information {
          avatar
          name
        }
      }
    }
  }
`;

const QUERY_ACCOUNT_BY_TYPE = gql`
  query ($accountType: String!) {
    findAccountByType(accountType: $accountType) {
      _id
      email
      name
      nickname
      avatar
      phone
      gender
      birthday
      address
      taxCode
      bio
      representative
      representativePosition
      companyType {
        _id
        value
      }
      companySize {
        _id
        value
      }
      companyIndustry {
        _id
        code
        value
      }
      companies {
      _id
      }
      influencer {
      _id
      }
      nation {
        _id
        code
        name
      }
      city {
        _id
        code
        name
      }
      state {
        _id
        code
        name
      }
      createdDate
    }
  }
`;

const QUERY_ACCOUNT_BY_INFLUENCER = gql`
  query ($influencerIds: [String!]) {
    getAccountByInfluencers(influencerIds: $influencerIds) {
      _id
      email
      name
      nickname
      avatar
      phone
      gender
      birthday
      address
      taxCode
      bio
      representative
      representativePosition
      companyType {
        _id
        value
      }
      companySize {
        _id
        value
      }
      companyIndustry {
        _id
        code
        value
      }
	  companies {
		_id
	  }
      nation {
        _id
        code
        name
      }
      city {
        _id
        code
        name
      }
      state {
        _id
        code
        name
      }
      createdDate
    }
  }
`;

const QUERY_FIND_RELATED_ACCOUNT = gql`
  query($company: String, $union: String, $influencer: String) {
    findRelatedAccounts(company: $company, union: $union, influencer: $influencer) {
      _id
      name
      nickname
      avatar
      receiver
      address
      bio
      company {
        _id
        information {
          name
          avatar
        }
      }
      influencer {
        _id
        information {
          name
          avatar
        }
      }
      friendStatus
      email
    }
  }
`;

const QUERY_COUNT_TOTAL_COMPANY_BY_INDUSTRY = gql`
  query ($input: AccountIndustryFilter!) {
    countTotalCompanyByIndustry(input: $input) {
      _id
      count
    }
  }
`;

const QUERY_COMPANY_BY_INDUSTRY = gql`
  query ($input: AccountIndustryFilter!) {
    getCompanyByIndustry(input: $input) {
      total
      page
      pageSize
      data {
        _id
        avatar
        email
        name
        nickname
        avatar
        phone
        gender
        birthday
        address
        accountType
        taxCode
        bio
        organizationCode
        zipcode
        gender
        website
        representative
        representativeEmail
        representativePhone
        representativePosition
        role {
          _id
        }
        companies {
          _id
        }
        unions {
          _id
        }
        companyType {
          _id
          value
        }
        companySize {
          _id
          value
        }
        companyIndustry {
          _id
          value
        }
        establishedDate
        markets {
          _id
        }
        mainIndustries {
          _id
        }
        mainProducts {
          _id
        }
        mainCustomers {
          _id
        }
        nation {
          _id
          name
        }
        city {
          _id
          name
        }
        state {
          _id
          name
        }
        status
        createdDate
        company {
          _id
          averageRating
        }
      }
    }
  }
`;

const MUTATION_ADD_ACCOUNT = gql`
  mutation($input: AccountInput!) {
    addAccount(input: $input)
  }
`;

const MUTATION_EDIT_ACCOUNT = gql`
  mutation($input: AccountInput!) {
    editAccount(input: $input)
  }
`;

const MUTATION_UPDATE_STATUS_ACCOUNT = gql`
  mutation($id: String!, $status: Boolean!) {
    updateStatusAccount(id: $id, status: $status)
  }
`;

const MUTATION_UPDATE_STATUS_COMPANY = gql`
  mutation($id: String!, $status: Boolean!) {
    updateStatusCompany(id: $id, status: $status)
  }
`;

const MUTATION_RESET_PASSWORD_ACCOUNT = gql`
  mutation($id: String!) {
    resetPasswrodAccount(id: $id)
  }
`;

const MUTATION_CHANGE_PASSWORD = gql`
  mutation($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

const MUTATION_ACCEPT_REQUEST_ACCOUNT = gql`
  mutation($accountIds: [String!]) {
    acceptRequestAccount(accountIds: $accountIds)
  }
`;

const MUTATION_DECLINE_REQUEST_ACCOUNT = gql`
  mutation($accountIds: [String!]) {
    declineRequestAccount(accountIds: $accountIds)
  }
`;

const MUTATION_ACCEPT_REPORT_ACCOUNT = gql`
  mutation($accountIds: [String!]) {
    acceptReportAccount(accountIds: $accountIds)
  }
`;

const MUTATION_DECLINE_REPORT_ACCOUNT = gql`
  mutation($accountIds: [String!]) {
    declineReportAccount(accountIds: $accountIds)
  }
`;

const MUTATION_REQUEST_INFLUENCER_JOIN_COMPANY = gql`
  mutation($company: String!, $influencer: String!, $isInfluencer: Boolean, $input: RequestJoinInfluencerInput) {
    requestInfluencerJoinCompany(company: $company, influencer: $influencer, isInfluencer: $isInfluencer, input: $input)
  }
`;

const MUTATION_DELETE_INFLUENCER_JOIN_COMPANY = gql`
  mutation($id: String!, $companyType: String!) {
    deleteInfluencerJoinCompany(id: $id, companyType: $companyType)
  }
`;


const  MUTATION_ACCEPT_FRIEND = gql`
  mutation($input: RelationInput!) {
    acceptFriend(input: $input)
  }
`;

const MUTATION_DECLINE_FRIEND = gql`
  mutation($accountId: String!) {
    declineFriend(accountId: $accountId)
  }
`;

const MUTATION_CHANGE_COMPANY = gql`
  mutation($id: String!, $company: String!, $companyType: String!) {
    switchCompany(id: $id, company: $company, companyType: $companyType)
  }
`;

const MUTATION_CHECK_IMPORT_ACCOUNT = gql`
  mutation($input: [AccountInput!]) {
    checkImportAccount(input: $input)
  }
`;

const MUTATION_IMPORT_ACCOUNT = gql`
  mutation($input: [AccountInput!]) {
    importAccount(input: $input)
  }
`;

const MUTATION_SET_PACKAGE = gql`
  mutation($input: setPackageInput!) {
    setPackage(input: $input)
  }
`;

const AccountGRAPHQL = {
  QUERY_ACCOUNT,
  QUERY_ACCOUNT_INFO,
  QUERY_ACCOUNT_BY_ID,
  QUERY_ACCOUNT_BY_IDS,
  QUERY_ORGANIZATION_WITH_FILTER,
  QUERY_ACCOUNT_WITH_FILTER,
  QUERY_ACCOUNT_MEDICAL,
  QUERY_NOFIFY_ACCOUNT,
  QUERY_COUNT_ACCOUNT_SUMMARY,
  QUERY_COMPANY_INFO,
  QUERY_ACCOUNT_FOR_POST,
  QUERY_COMPANY_ACCOUNT_BY_ROLE,
  QUERY_REQUEST_ACCOUNT,
  QUERY_REPORTED_ACCOUNT,
  QUERY_PENDING_INDIVIDUAL_REQUEST,
  QUERY_PENDING_FRIEND_BY_USER,
  QUERY_PENDING_FRIEND_BY_USER_WITH_FILTER,
  QUERY_COMPANY_BY_GOVERMENT,
  QUERY_ACCOUNT_BY_COMPANY_ID,
  QUERY_FIND_INDIVIDUAL_ACCOUNT,
  QUERY_ACCOUNT_BY_TYPE,
  QUERY_ACCOUNT_BY_INFLUENCER,
  QUERY_ORGANIZATION_NETWORK,
  QUERY_EMPLOYEE_NETWORK,
  QUERY_FIND_RELATED_ACCOUNT,
  QUERY_COUNT_TOTAL_COMPANY_BY_INDUSTRY,
  QUERY_COMPANY_BY_INDUSTRY,
  MUTATION_ADD_ACCOUNT,
  MUTATION_EDIT_ACCOUNT,
  MUTATION_UPDATE_STATUS_ACCOUNT,
  MUTATION_UPDATE_STATUS_COMPANY,
  MUTATION_RESET_PASSWORD_ACCOUNT,
  MUTATION_CHANGE_PASSWORD,
  MUTATION_ACCEPT_REQUEST_ACCOUNT,
  MUTATION_DECLINE_REQUEST_ACCOUNT,
  MUTATION_ACCEPT_REPORT_ACCOUNT,
  MUTATION_DECLINE_REPORT_ACCOUNT,
  MUTATION_REQUEST_INFLUENCER_JOIN_COMPANY,
  MUTATION_DELETE_INFLUENCER_JOIN_COMPANY,
  MUTATION_ACCEPT_FRIEND,
  MUTATION_DECLINE_FRIEND,
  MUTATION_CHANGE_COMPANY,
  MUTATION_CHECK_IMPORT_ACCOUNT,
  MUTATION_IMPORT_ACCOUNT,
  MUTATION_SET_PACKAGE
};


export default AccountGRAPHQL;